import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export enum PERMISSION_KIDS {
  // ? APP CREATED
  NONE_REQUIRED = 'NONE_REQUIRED',
  FRONTEND_NOT_USED = 'FRONTEND_NOT_USED',

  // ? DB CREATED
  // ? INTERNAL - hidden unless permission granted
  INT_KALPA_MAINTENANCE = 'INT_KALPA_MAINTENANCE',
  INT_KALPA_SWITCH_USER = 'INT_KALPA_SWITCH_USER',

  // ? EXTERNAL - hidden unless permission granted
  // SWAP_PRESIDENT = 'SWAP_PRESIDENT',

  // ? EXTERNAL
  COURSE_ADD = 'COURSE_ADD',
  COURSE_EDIT = 'COURSE_EDIT',
  COURSE_PUSH = 'COURSE_PUSH',
  COURSE_REQUESTS = 'COURSE_REQUESTS',
  COURSE_SUGGESTIONS = 'COURSE_SUGGESTIONS',

  EDIT_BULLETIN_BOARD = 'EDIT_BULLETIN_BOARD',
  EXPORT_STATE_DOC = 'EXPORT_STATE_DOC',

  MANAGE_SURVEYS = 'MANAGE_SURVEYS',
  RUN_DATA_REPORTS = 'RUN_DATA_REPORTS',

  SYS_SETTINGS_ACCESS = 'SYS_SETTINGS_ACCESS',
  SYS_SETTINGS_DEFAULTS = 'SYS_SETTINGS_DEFAULTS',
  SYS_SETTINGS_BUILDINGS = 'SYS_SETTINGS_BUILDINGS',
  SYS_SETTINGS_POSITIONS = 'SYS_SETTINGS_POSITIONS',
  SYS_SETTINGS_REQUIREMENTS = 'SYS_SETTINGS_REQUIREMENTS',
  SYS_SETTINGS_TYPES = 'SYS_SETTINGS_TYPES',
  SYS_SETTINGS_CATEGORIES = 'SYS_SETTINGS_CATEGORIES',
  SYS_SETTINGS_TAGS = 'SYS_SETTINGS_TAGS',
  SYS_SETTINGS_ROLES = 'SYS_SETTINGS_ROLES',

  USER_ACCOUNTS = 'USER_ACCOUNTS',
  USER_ADD = 'USER_ADD',
  USER_EDIT = 'USER_EDIT',

  VALIDATE_ATTENDANCE = 'VALIDATE_ATTENDANCE',
}

export const PermissionGuard = (permissionNames: keyof typeof PERMISSION_KIDS | (keyof typeof PERMISSION_KIDS)[], requireAll = false) => () => {
  const router = inject(Router)
  const authService = inject(AuthService)
  let foundUserPermissions: any;

  const authSubscription = authService.permissions$
    .subscribe((userPermissions: Record<PERMISSION_KIDS, 0 | 1>) => {
      foundUserPermissions = userPermissions
    })

  if (Array.isArray(permissionNames)) {
    const missingPermissions = permissionNames.filter((permissionName) => !PERMISSION_KIDS[permissionName]);

    if (missingPermissions.length) {
      throw new Error(`Permissions ${missingPermissions.join(', ')} not found`)
    }

  } else {
    const mappedPermissionName = PERMISSION_KIDS[permissionNames];

    if (!PERMISSION_KIDS[mappedPermissionName]) {
      throw new Error(`Permission ${permissionNames} not found`)
    }

  }

  if (Array.isArray(permissionNames)) {
    const method = requireAll ? 'every' : 'some';
    const hasPermissions = permissionNames[method]((permissionName) => {
      const mappedPermissionName = PERMISSION_KIDS[permissionName];

      return foundUserPermissions && foundUserPermissions[mappedPermissionName] == 1;
    });

    if (hasPermissions) {
      return true;
    }

  } else {
    if (foundUserPermissions && foundUserPermissions[permissionNames] == 1) {
      return true;
    }
  }

  authSubscription.unsubscribe();
  router.navigate([ '/', 'home' ])
  return false
}

